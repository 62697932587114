/**
 * DO NOT EDIT: This file has been generated by a script. Any changes
 * made will be overwritten.
 */

export default [
  { id: 0, key: "jpy", display: "Japanese Yen" },
  { id: 2, key: "usd", display: "United States Dollar" },
  { id: 3, key: "eur", display: "Euro" },
  { id: 4, key: "gbp", display: "British Pound Sterling" },
  { id: 5, key: "btc", display: "Bitcoin" },
  { id: 6, key: "aud", display: "Australian Dollar" },
  { id: 7, key: "bgn", display: "Bulgarian Lev" },
  { id: 8, key: "brl", display: "Brazilian Real" },
  { id: 9, key: "cad", display: "Canadian Dollar" },
  { id: 10, key: "chf", display: "Swiss Franc" },
  { id: 11, key: "cny", display: "Chinese Yuan" },
  { id: 12, key: "czk", display: "Czech Republic Koruna" },
  { id: 13, key: "dkk", display: "Danish Krone" },
  { id: 14, key: "hkd", display: "Hong Kong Dollar" },
  { id: 15, key: "hrk", display: "Croatian Kuna" },
  { id: 16, key: "huf", display: "Hungarian Forint" },
  { id: 17, key: "idr", display: "Indonesian Rupiah" },
  { id: 18, key: "ils", display: "Israeli New Sheqel" },
  { id: 19, key: "inr", display: "Indian Rupee" },
  { id: 20, key: "isk", display: "Icelandic Króna" },
  { id: 21, key: "krw", display: "South Korean Won" },
  { id: 22, key: "mxn", display: "Mexican Peso" },
  { id: 23, key: "myr", display: "Malaysian Ringgit" },
  { id: 24, key: "nok", display: "Norwegian Krone" },
  { id: 25, key: "nzd", display: "New Zealand Dollar" },
  { id: 26, key: "php", display: "Philippine Peso" },
  { id: 27, key: "pln", display: "Polish Zloty" },
  { id: 28, key: "ron", display: "Romanian Leu" },
  { id: 29, key: "rub", display: "Russian Ruble" },
  { id: 30, key: "sek", display: "Swedish Krona" },
  { id: 31, key: "sgd", display: "Singapore Dollar" },
  { id: 32, key: "thb", display: "Thai Baht" },
  { id: 33, key: "try", display: "Turkish Lira" },
  { id: 34, key: "vnd", display: "Vietnamese Dong" },
  { id: 35, key: "zar", display: "South African Rand" },
];
