<template>
  <slot />

  <form
    id="tipped-user-settings"
    class="m-1 mb-3"
  >
    <div class="row mt-3 mt-md-auto mb-md-3">
      <div class="col">
        <div class="text-center fs-3 fw-300">Quick Access</div>
        <hr>
      </div>
    </div>

    <div class="mb-3">
      <div class="row">
        <div
          class="col-sm-12 col-md-5 px-0 py-2 py-md-0 text-center text-md-end"
        >
          <span class="align-middle fsr-1">Search Region</span>
        </div>
        <div class="col text-left">
          <select name="region" class="w-100">
            <option
              v-for="region in searchRegions"
              :key="region.id"
              :selected="this.selectedRegionKey == region.key"
              :value="region.key">{{ region.name }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="row">
        <div
          class="col-sm-12 col-md-5 px-0 py-2 py-md-0 text-center text-md-end"
        >
          <span class="align-middle fsr-1">Result Layout</span>
        </div>
        <div class="col text-left">
          <select name="layout" class="w-100">
            <option
              v-for="layout in searchLayouts"
              :selected="this.selectedLayoutKey == layout.key"
              :key="layout.id"
              :value="layout.key">{{ layout.name }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="row">
        <div
          class="col-sm-12 col-md-5 px-0 py-2 py-md-0 text-center text-md-end"
        >
          <span class="align-middle fsr-1">Currency</span>
        </div>
        <div class="col text-left">
          <select name="currency" class="w-100">
            <template v-for="currency in itemCurrencies" :key="currency.id">
              <option
                :selected="this.selectedCurrencyKey == currency.key"
                :value="currency.key">{{ currency.display }}</option>
            </template>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="d-grid">
        <input
          class="btn"
          type="button"
          :value="status" @click="saveSettings">
      </div>
    </div>

    <div class="form-group">
      <div class="d-grid">
        <hr>
        <a class="btn" href="/logout">Sign-out?</a>
      </div>
    </div>


  </form>

</template>

<script>
  import tippy from 'tippy.js';
  import 'tippy.js/dist/tippy.css';
  import 'tippy.js/animations/scale.css';
  import axios from 'axios';
  import VueAxios from 'vue-axios';

  import currencyData from '../lib/currency-data';

  export default {
    created() {},
    mounted()
    {
      let navBarUserEl = document.getElementById('navUser');
      let contentEl = document.getElementById('tipped-user-settings');

      if (navBarUserEl && contentEl)
      {
        this.tippInst = tippy(
          navBarUserEl,
          {
            arrow: true,
            allowHTML: true,
            trigger: 'click',
            interactive: true,
            content: contentEl,
            placement: 'bottom',
          }
        );

        this.updateSelectedSettings();
      }
    },
    data ()
    {
      return {
        contentEl: null,
        busy: false,
        savedSettings: false,
        selectedRegionKey: null,
        selectedLayoutKey: null,
        selectedCurrencyKey: null,
        searchRegions: [
          {
            id: 0,
            key: 'int',
            name: 'International'
          },
          {
            id: 1,
            key: 'jp',
            name: 'Japan'
          }
        ],
        searchLayouts: [
          {
            id: 0,
            key: 'card',
            name: 'Card'
          },
          {
            id: 1,
            key: 'detailed',
            name: 'Detailed'
          }
        ],
        itemCurrencies: currencyData
      }
    },
    methods:
    {
      saveSettings(e)
      {
        e.preventDefault();

        this.busy = true;

        let form = document.getElementById('tipped-user-settings');

        this
          .$http
          .post(
            `${process.env.DOMAIN}/user/setting`,
            new FormData(form)
          ).then((result) => {
            // https://slist.amiami.jp/top/search/list3?s_cate2=459&s_condition_flg=1&s_st_condition_flg=1&pagemax=60&getcnt=0&pagecnt=2

            // TODO: Store the settings in the user's local storage.
            // When the page loads, pull the settings from the local
            // storage and into the application's store.

            let userSettings = result.data;

            if (window.localStorage)
            {
              [
                'item_currency',
                'result_layout',
                'search_region'
              ].forEach((item) => {
                window.localStorage.setItem(item, userSettings[item]);
              });
            }

            this.updateSelectedSettings();

            this.savedSettings = true;
          }).finally(() => {
            this.busy = false;

            // NOTE: Due to mismatched values, it is best to forcibly reload
            // the page. Otherwise, USD prices may be mixed with JPY or
            // international results with Japanese ones.
            //
            // Consider only reloading the page *when* the results are shown.
            this.reloadPage();
          });
      },
      isPrimaryCurrency(currencyCode)
      {
        // console.log(currencyCode);

        return ['jpy', 'usd', 'gbp', 'eur'].indexOf(currencyCode) != -1;
      },
      updateSelectedSettings()
      {
        if (window.localStorage)
        {
          this.selectedRegionKey = window.localStorage.getItem(
            'search_region'
          );

          this.selectedLayoutKey = window.localStorage.getItem(
            'result_layout'
          );

          this.selectedCurrencyKey = window.localStorage.getItem(
            'item_currency'
          );
        }
      },
      reloadPage()
      {
        window.location.reload();
      }
    },
    computed:
    {
      status()
      {
        return this.busy ? 'Saving...' : 'Save'
      }
    }
  }
</script>

<style type="style/css" lang="scss">
.tippy-box
{
  background: #484641cf;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}

.tippy-arrow
{
 color: #484641cf;
}
</style>

<!--
<template v-if="savedSettings">
  <div class="row">
    <div class="col text-center pt-1 pb-2">
      <hr>
      <strong>Changes will be applied upon page reload.</strong>&nbsp;
      <a
        href="#"
        class="text-reset text-decoration-underline"
        @click="reloadPage">Reload now?</a>
    </div>
  </div>
</template>

-->

